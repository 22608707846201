import React, { useState } from "react";
import { Link } from "react-router-dom";
import { screen } from '@testing-library/react';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const RadialMenu = ({ role, user, screen }) => {
    // console.log("user",user,role)
  const [isMenu1Active, setMenu1Active] = useState(false);

  const toggleMenu1 = () => {
    setMenu1Active(!isMenu1Active);
  };
  let link1 = "";
  let link2 = "";
  let link1Tooltip="";
  let link2Tooltip="";
  if (role === "admin") {
    if (user === "User") {
      link1 = "/employee";
      link2 = "/course";
      link1Tooltip="Add Skillset"
      link2Tooltip="Add Courses"
    } else {
      link1 = "/admin";
      link2 = "/skills";
      link1Tooltip="List Skillset"
      link2Tooltip="Add New Skills"
    }
    // link1 = "/";
    // link2 = "/skills";
  } else {
    link1 = "/user";
    link2 = "/course";
    link1Tooltip="Add Skillset"
    link2Tooltip="Add Courses"
  }
  return (
    <div
      id="circularMenu1"
      className={`circular-menu circular-menu-left ${
        isMenu1Active ? "active" : ""
      }`}
    >
      <a className="floating-btn" onClick={toggleMenu1}>
        <i className="fa fa-bars" />
      </a>
      <menu className="items-wrapper">
        <Link to={link1} className="menu-item fa fa-home" data-tooltip-id="tableValue" data-tooltip-content={link1Tooltip} />
        <Link to="/listcourse" className={screen&&"menu-item fas fa-eye"} data-tooltip-id="tableValue" data-tooltip-content={"List Courses"}/> 
        <Link to={link2} href="#" className="menu-item fa-solid fa-plus" data-tooltip-id="tableValue" data-tooltip-content={link2Tooltip}/>
         
        {/* <a href="#" className="menu-item fa fa-cog" /> */}
      </menu>
      <ReactTooltip id="tableValue" />
    </div>
  );
};

export default RadialMenu;
