import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import TableRow from "../components/TableRow";
import AdminTableRow from "../components/AdminTableRow";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import AddEmployee from "../components/AddEmployee";
import instance from "../utils/axios";
import Pagination from "@mui/material/Pagination";
import formatDate from "../utils/formatDate";
const AdminScreen = ({ name, role, picture }) => {
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [searchList, setSearchList] = useState({
    employeeId: "",
    name: "",
    skill: "",
    noSkillAdded: false,
    // skillAdded false means skill is not added
  });

  const handleToggleEdit = (rowId) => {
    // console.log("This is row id", rowId)
    setCurrentlyEditing(rowId);
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
    // setRefresh(!refresh);
    // alert(value)
  };

  useEffect(() => {
    const fetchAllEmployees = async () => {
      try {

        // console.log("This is search list ", searchList)
        const data = await instance.post("/employee/employeeList", {
          page: currentPage - 1,
          dataCount: 10,
          searchList: searchList,
        });
        // console.log("This  is employee list ", data?.data?.data.map((item)=>item.skills));
        // Api response and search list is correct . Search issue is below
        if (data) {
          // Search issue is here while setting setAllEmployees() value updating on next refresh.. 
          // In admin table item is consoled even before the searchList is consoled
          // Need to fix that
          setAllEmployees(data?.data?.data);
          setTotalPages(data?.data?.totalPages);
        }
        else {
          console.log("Employee list not found")
        }

      } catch (error) {
        console.log("Error in fetchAllEmployees", error);
      }
    };
    fetchAllEmployees();
  }, [refresh, currentPage, searchList]);
  const [noSkills, setNoSkills] = useState(true);
  const handleSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.name === "noSkillAdded") {
      // alert("Skill Added");
      // e.target.value === "No skills added" ? (value = true) : (value = false);
      setNoSkills(!noSkills);
      value = noSkills;
    }

    setSearchList({
      ...searchList,
      [name]: value,
    });
    setCurrentPage(1);
  };



  // useEffect(() => {
  //   console.log("This is search list", searchList);
  //   console.log("This is all employees list for checking", allEmployees)
  // }, [allEmployees]);
  const generateCSV = (data) => {
    const header = ['Employee ID', 'Name', 'Skills', 'Status', 'Role', 'Last Updated', "courses"];
    const rows = data.map(item => {
      const dateArray = item?.skills?.map(item => new Date(item.updatedAt));
      const latestTimestamp = new Date(Math.max(...dateArray));
      const updatedAt = formatDate(latestTimestamp);
      return ([
        item.basicDetails?.employeeId || '',
        item.basicDetails?.name || '',
        item.skills?.map(e => e.skillName)?.join(' | ') || '',
        item?.basicDetails?.status || '',
        item?.basicDetails?.role || '',
        updatedAt === "NaN-NaN-NaN" ? "Not added skills" : updatedAt,
        item.courses?.map(e => e.courseName)?.join(' | ') || '',
      ])
    });

    const csvContent = [
      header.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    return csvContent;
  };

  const downloadCSV = async () => {
    const data = await instance.post("/employee/employeeList", {
      page: 0,
      dataCount: 10 * totalPages,
      searchList: searchList,
    });
    const csvContent = generateCSV(data.data.data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "EmployeeSkillsReport.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Cleanup
    URL.revokeObjectURL(url); // Release memory
  };
  return (
    <div className="main-content">
      <Navbar name={name} role={role} picture={picture} screen="Admin" />
      <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8 ">
        {/* ------ Add Employee -------- */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "35px",
          }}
        >
          <button
            className="blue-button"
            onClick={() => setOpenAddEmployee(true)}
          >
            Add Employee
          </button>
          <button
            className="blue-button"
            onClick={downloadCSV}
            style={{ marginLeft: "10px" }}
          >
            Download CSV
          </button>
        </div>
        {/* --------- Filter ------------- */}
        <div className="container">
          <div className="input-row">
            <div className="input-container">
              <label className="input-label" htmlFor="employeeId">
                Employee ID :
              </label>
              <input
                type="text"
                id="employeeId"
                className="input-field"
                placeholder="Search by Employee ID"
                name="employeeId"
                onChange={handleSearch}
              />
            </div>

            <div className="input-container">
              <label className="input-label" htmlFor="name">
                Name :
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="input-field"
                placeholder="Search by Name"
                onChange={handleSearch}
              />
            </div>

            <div className="input-container">
              <label className="input-label" htmlFor="skill">
                Skill :
              </label>
              <input
                type="text"
                id="skill"
                name="skill"
                className="input-field"
                placeholder="Search by Skill"
                onChange={handleSearch}
              />
            </div>

            <div className="input-container">
              <label className="input-label" htmlFor="birthdate">
                Filter:
              </label>
              <input
                type="button"
                id="birthdate"
                className="blue-button"
                value={noSkills ? "No skills added" : "List all employees"}
                name="noSkillAdded"
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
        {/* ...................................... */}
      </div>
      {/* -------- Table -------------------- */}
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card shadow">
              <div className="card-header border-0">
                <h3 className="mb-0">Employee Skill List</h3>
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Skills</th>
                      <th scope="col">Status</th>
                      <th scope="col">Role</th>
                      <th scope="col">Last Updated</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {allEmployees?.map((item, index) => {
                      return (
                        <AdminTableRow
                          key={index}
                          item={item}
                          isEditing={
                            currentlyEditing === item?.basicDetails?._id
                          }
                          onToggleEdit={handleToggleEdit}
                          currentPage
                          setRefresh={setRefresh}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="card-footer py-4 d-flex justify-content-end">
                {/* <nav aria-label="...">
                  <ul className="pagination justify-content-end mb-0">
                    <li className="page-item disabled">
                      <a className="page-link" href="#" tabIndex={-1}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2 <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav> */}
                <Pagination
                  count={totalPages}
                  // color="primary"
                  className="pagination"
                  sx={{
                    '.MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                    '& .MuiPaginationItem-page:hover': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                  }}
                  page={currentPage}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {openAddEmployee && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal
            centered
            show={openAddEmployee}
            onHide={() => setOpenAddEmployee(false)}
            dialogClassName="my-modal"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header className="m-2 p-2" closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                ADD EMPLOYEE
              </Modal.Title>
            </Modal.Header>
            <AddEmployee
              openAddEmployee={openAddEmployee}
              setOpenAddEmployee={setOpenAddEmployee}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AdminScreen;
